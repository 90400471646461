import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Text, Flex } from 'baselift'
import Seo from '../components/shared/seo'
import { Duration, DateTime } from 'luxon'
import { useTheme } from '../theme/use-theme'
import { useBreakpoints } from '../context/breakpoints-context'
import { PostBySlugQuery } from '../../types/graphql-types'
import { useI18n } from '../i18n/i18n-context'
import { Content } from '../components/shared/content'
import { WavyHero } from '../components/shared/wavy-hero'

interface IPost {
  slug?: string
  title?: string
  subtitle?: string
  createdAt?: string
  updatedAt?: string
  timeToRead?: number
  description?: {
    description?: string
  }
  body?: {
    childMarkdownRemark: {
      html: string
    }
  }
}

const applyTabIndexToPreTagsToAllowFocusAndScroll = (html: string) => {
  return html.replace(/<pre /g, '<pre tabindex="0" ')
}

const Post: FC<PageProps<any>> = ({ data }) => {
  const {
    slug,
    title,
    subtitle,
    timeToRead,
    createdAt,
    updatedAt,
  } = data.contentfulJmBlogPost as IPost

  const html = data?.contentfulJmBlogPost?.body?.childMarkdownRemark?.html ?? ''

  const theme = useTheme()
  const { bp } = useBreakpoints()

  return (
    <>
      <Seo
        title={title ?? ''}
        description={title ?? ''}
        pathname={`/blog/${slug}`}
        article={{
          author: 'Jonathan Madelaine',
          createdAt,
          updatedAt,
        }}
      />
      <article
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <WavyHero>
          <Text
            element="h1"
            css={bp({
              fontSize: [theme.fontSizes[6], theme.fontSizes[7]],
            })}
          >
            {title}
          </Text>
          <Text
            element="p"
            css={bp({
              fontSize: [theme.fontSizes[4], theme.fontSizes[5]],
              color: 'var(--post-minor-text)',
              transition: 'color 0.5s',
            })}
          >
            {subtitle}
          </Text>
          <Metadata
            css={bp({
              marginTop: theme.space('xs'),
            })}
            createdAt={createdAt}
            updatedAt={updatedAt}
            timeToRead={timeToRead}
          />
        </WavyHero>
        <Content
          css={{
            maxWidth: theme.size(11),
          }}
        >
          <Flex
            css={bp({
              position: 'relative',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              color: 'var(--post-body-text)',
              '& p': {
                fontWeight: 300,
                fontSize: [theme.fontSizes[3], theme.fontSizes[4]],
                marginBottom: theme.space('m'),
              },
              '& h2': {
                position: 'relative',
                fontSize: [theme.fontSizes[5], theme.fontSizes[6]],
                marginTop: [theme.space('m'), theme.space('l')],
                marginBottom: [theme.space('s'), theme.space('m')],
                '& a': {
                  background: 0,
                  border: 0,
                  '&:hover': {
                    background: 0,
                    border: 0,
                  },
                  '&:focus': {
                    background: 0,
                    border: 0,
                  },
                },
                '& svg': {
                  fill: 'var(--post-body-text)',
                  marginRight: [0, 0, '4px'],
                  marginLeft: [0, 0, '4px'],
                },
              },
              '& h3': {
                position: 'relative',
                fontSize: [theme.fontSizes[4], theme.fontSizes[5]],
                marginTop: [theme.space('s'), theme.space('m')],
                marginBottom: [theme.space('xs'), theme.space('s')],
              },
              '& pre': {
                borderRadius: [0, 0, '8px'],
                paddingLeft: [theme.space('s'), theme.space('m')],
                paddingRight: [theme.space('s'), theme.space('m')],
                marginLeft: [`-${theme.space('s')}`, `-${theme.space('m')}`],
                marginRight: [`-${theme.space('s')}`, `-${theme.space('m')}`],
                marginBottom: theme.space('m'),
              },
              '& a': {
                background: 'var(--anchor-text-background)',
                textDecoration: 'none',
                borderBottom: '1px solid var(--anchor-text-border)',
                padding: '2px 4px',
                borderRadius: '1px',
                '&:hover': {
                  background: 'var(--anchor-text-background--hover)',
                  borderBottom: '1px solid var(--anchor-text-border--hover)',
                },
                '&:focus': {
                  background: 'var(--anchor-text-background--hover)',
                  borderBottom: '1px solid var(--anchor-text-border--hover)',
                },
                '& code': {
                  padding: 0,
                  background: 0,
                },
              },
            })}
            dangerouslySetInnerHTML={{
              __html: applyTabIndexToPreTagsToAllowFocusAndScroll(html),
            }}
          />
        </Content>
      </article>
    </>
  )
}

export default Post

interface IArticleMetaDataProps {
  createdAt: any
  updatedAt: any
  timeToRead: any
}

const Metadata: FC<IArticleMetaDataProps> = ({ createdAt, updatedAt, timeToRead, ...other }) => {
  const { t } = useI18n()

  const created = DateTime.fromISO(createdAt)
  const updated = DateTime.fromISO(updatedAt)
  const updatedFriendly = created.hasSame(updated, 'day')
    ? ''
    : ` ${t('blog:post_updated', {
        date: updated.toLocaleString(DateTime.DATE_MED),
      })}`
  const timeToReadFriendly = Duration.fromObject({ minutes: timeToRead }).minutes
  const updatedDiff = updated.diff(created).minutes

  return (
    <Flex
      css={{
        color: 'var(--post-minor-text)',
        transition: 'color 0.5s',
      }}
      {...other}
    >
      <Text>
        {created.toLocaleString(DateTime.DATE_MED)}
        {updatedFriendly}
        {updatedDiff ? updatedDiff : ''}
        {` · ${t('blog:post_to_read', {
          minutes: timeToReadFriendly,
        })}`}
      </Text>
    </Flex>
  )
}

export const query = graphql`
  query PostBySlug($slug: String!) {
    contentfulJmBlogPost(slug: { eq: $slug }) {
      slug
      title
      subtitle
      timeToRead
      body {
        childMarkdownRemark {
          html
        }
      }
      createdAt
      updatedAt
    }
  }
`
